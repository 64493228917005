<template>
  <v-card outlined width="320" style="margin: calc(50vh - 150px) auto 0 auto">
    <v-card-text class="pt-5 pb-5">
      <v-text-field outlined label="Login" v-model="login"/>
      <v-text-field outlined label="Hasło" v-model="password" :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show"/>
      <v-row class="justify-center mt-n1 mb-7">
        <v-btn outlined class="primary--text" @click="tryToLogin()">ZALOGUJ SIĘ</v-btn>
      </v-row>
      <v-alert outlined dense color="error" v-if="error !== ''">{{ error }}</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AdminLogin',
  data: () => ({
    login: '', password: '',
    show: false, error: ''
  }),
  methods: {
    tryToLogin() {
      this.$http.post('/api/admin/auth/login', {login: this.login, password: this.password}).then((res) => {
        localStorage.setItem('token', res.data);
        this.$router.push('/admin');
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 403) this.error = 'Nieprawidłowy login lub hasło.';
          else if (err.response.status === 500) this.error = 'Błąd wewnętrzny serwera.';
          else this.error = 'Wystąpił nieoczekiwany błąd.';
        } else this.error = 'Wystąpił nieoczekiwany błąd.';
      });
    }
  },
  watch: {
    login: function () {
      this.error = '';
    },
    password: function () {
      this.error = '';
    }
  }
};
</script>
